import { defineStore } from "pinia";
import { ref } from "vue";
import { AppConfig}  from '../config'
import { Link, Channel, List } from "../models/link";
import { updateLinkUrl, convertResToLinks } from "./utils";

export const useChannelStore = defineStore('channels', () => {
    const allChannels = ref(<Channel[]>[]);
    const channelLinks = ref(<Link[]>[]);
    const channelQuickLinks = ref(<Link[]>[]);
    const channelLists = ref(<List[]>[]);
    let channel = ref({} as Channel);
    // Add Amazon links

    async function fetchChannel(channelId: string) {
        try {
          const response = await fetch(AppConfig.API_DOMAIN + `/channels/${channelId}`, {
            credentials: 'include'
          });
          if (!response.ok) {
            throw new Error(`${response.status}: ${response.statusText}`);
          }
          const getChannel = await response.json();
          channel.value = {
              id: getChannel.id,
              name: getChannel.name,
              imageUrl: getChannel.imageUrl,
              youtubeCustomUrl: getChannel.ytCustomUrl
          } as Channel
        }
        catch (error) {
            alert(error)
            console.log(error)
        }
    }

    async function updateChannels() {
      try {
        const response = await fetch(AppConfig.API_DOMAIN + `/youtube/subscriptions`, {
          credentials: 'include'
        });
        if (!response.ok) {
          throw new Error(`${response.status}: ${response.statusText}`);
        }
      }
      catch (error) {
          alert(error)
          console.log(error)
      }
    }

    async function fetchChannelLists(channelId: string) {
      try {
        const response = await fetch(AppConfig.API_DOMAIN + `/channels/${channelId}/lists`, {
          credentials: 'include'
        });
        if (!response.ok) {
          throw new Error(`${response.status}: ${response.statusText}`);
        }
        const getLists = await response.json();
        let _lists = getLists.map((el: ChannelListsRes) => {
          return {
            id: el.id,
            name: el.name,
            imageUrl: el.links && el.links.length > 0 ? el.links[0].imageUrl : '',
            links: el.links.map(updateLinkUrl),
          } as List
        });
        channelLists.value = _lists;
      }
      catch (error) {
          alert(error)
          console.log(error)
      }
    }

    async function fetchChannelQuicklinks(channelId: string) {
      try {
        const response = await fetch(AppConfig.API_DOMAIN + `/channels/${channelId}/quick-links`, {
          credentials: 'include'
        });
        if (!response.ok) {
          throw new Error(`${response.status}: ${response.statusText}`);
        }
        const getLists = await response.json();
        channelQuickLinks.value = getLists.map(convertResToLinks);
      }
      catch (error) {
          alert(error)
          console.log(error)
      }
    }

    async function addQuicklink(channelId: string, linkId: string) {
      try {
        const response = await fetch(AppConfig.API_DOMAIN + `/channels/${channelId}/quick-links/${linkId}`, {
          method: 'POST',
          credentials: 'include'
        });
        if (!response.ok) {
          throw new Error(`${response.status}: ${response.statusText}`);
        }
        fetchChannelQuicklinks(channelId); 
      }
      catch (error) {
          alert(error)
          console.log(error)
      }
    }

    async function deleteQuicklink(channelId: string, linkId: string) {
      try {
        const response = await fetch(AppConfig.API_DOMAIN + `/channels/${channelId}/quick-links/${linkId}`, {
          method: 'DELETE',
          credentials: 'include'
        });

        if (!response.ok) {
          throw new Error(`${response.status}: ${response.statusText}`);
        }

        fetchChannelQuicklinks(channelId); 
      }
      catch (error) {
          alert(error)
          console.log(error)
      }
    }

    /*
    TODO: 
      - Update amazonLinks
    */
    async function fetchChannels() {
      try {
        const response = await fetch(AppConfig.API_DOMAIN + `/channels`, {
          credentials: 'include'
        });
        if (!response.ok) {
          throw new Error(`${response.status}: ${response.statusText}`);
        }
        const getChannels = await response.json();
        getChannels.forEach((el: ChannelRes) => {
          let channel: Channel = {
            id: el.id,
            name: el.name,
            youtubeId: el.youtubeId,
            imageUrl: el.imageUrl,
            quickLinks: el.quickLinks
          };          
          allChannels.value.push(channel);
          channelLinks.value.push({id: channel.id, type: 'channel', channel: channel} as Link);
        });
      }
      catch (error) {
        alert(error)
        console.log(error)
      }
    }

    function reset() {
      allChannels.value = [];
      channelLinks.value = [];
      channelLists.value = [];
      channel.value = {} as Channel;
      channelQuickLinks.value = [];
    }
  
    return {
      allChannels, channelLinks, channelLists, channel, channelQuickLinks,
      fetchChannels, fetchChannel, fetchChannelLists, fetchChannelQuicklinks, addQuicklink, deleteQuicklink, updateChannels, reset
    }
  });

export interface ChannelRes {
    id: string,
    name: string,
    imageUrl: string
    youtubeId?: string,
    quickLinks?: Link[]
}

export interface ChannelListsRes {
  id: string,
  channelId: string,
  name: string,
  links: Link[]
}