<script setup lang="ts">
import { useRoute } from 'vue-router';
import LinkList from '../components/LinkList.vue';
import { storeToRefs } from 'pinia';
import { useListsStore } from '../store/lists';
import { mdiChevronLeft } from '@mdi/js';

const route = useRoute();
const listStore = useListsStore();

let channelId = route.params.channelId as string;
let listId = route.params.listId as string;
listStore.fetchList(listId);

const { list } = storeToRefs(listStore);
</script>

<template>
<router-link :to="`/channels/${channelId}`" :replace="true" class="back-link">
    <v-icon :icon="mdiChevronLeft" size="large" color="blue-grey-darken-2"></v-icon>
    back
</router-link>

<div class="channel-brand">
    <img class="channel-avatar" :src="list.imageUrl" :alt="list.name">
    <div class="channel-name">
        <h2>{{list.name}}</h2>
    </div>
</div>

<LinkList :links="list.links"/>

</template>

<style scoped>
.channel-brand {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.channel-name {
    text-align: left;
}
.channel-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}
.back-link {
    display: flex;
}
</style>