<script setup lang="ts">
import { Link } from '../../models/link'
import { toRefs } from 'vue'

    const props = defineProps<{link: Link}>();
    const { link } = toRefs(props);
</script>

<template>
<li>
    <a class="link link-affiliate" :href="link.url">
        <div class="channel">
            <img class="channel-avatar" :src="link.channel.imageUrl" :alt="link.channel.name">
            <h3 class="channel-name">{{link.channel.name}}</h3>
        </div>
        <div class="affiliate">
            <img class="affiliate-favicon" :src="link.imageUrl" :alt="link.name">
        </div>
    </a>
</li>
</template>

<style scoped>
    .link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border-radius: 5px;
    }
    .link:hover {
        background-color: #efefef;
    }
    .link-affiliate {
        display: flex
    }
    .channel {
        display: flex;
        align-items: center;
        
    }
    .channel-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    .channel-name {
        font-size: 18px;
        line-height: 18px;
        margin-left: 10px;
        color: #333;
    }
    .affiliate {
        display: flex;
        align-items: center;
    }

    .affiliate-favicon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
</style>