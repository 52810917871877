<script setup lang="ts">
import { toRefs, ref, watch } from 'vue'
import { List } from '../../models/link';
import { useListsStore } from '../../store/lists';
import { storeToRefs } from 'pinia';
import { mdiFormatListBulleted, mdiPlus } from '@mdi/js';

const props = defineProps({
  channelId: {type: String, required: true},
  linkId: {type: String, required: true},
  show: {type: Boolean}
});
const { show, channelId, linkId } = toRefs(props);

const listStore = useListsStore();

const { lists } = storeToRefs(listStore);

const newListName = ref('');
const isLoading = ref(true);
const selectedItem = ref<List>();

watch(channelId, (newChannelId, _) => {
  if (newChannelId.length > 0) {
    listStore.fetchLists(channelId.value).then(() => isLoading.value = false);
  }
});

function onSearchUpdate(txt: string) {
    newListName.value = txt;
    console.log(newListName.value);
}

function onCreateListClick() {
    isLoading.value = true;
    console.log(`Create list named ${newListName.value}`);
    // Create List
    let newList = {id: '', name: newListName.value};
    // Add List to lists
    lists.value.push(newList);
    // set selectedItem to the created list
    selectedItem.value = newList;
    isLoading.value = false;
}

async function onAddBtnClick() {
  isLoading.value = true;
  // if list is new, create list
  let selectedList = selectedItem.value;
  if (selectedItem.value!.id.length < 10 && selectedItem.value!.name) {
    selectedList = await listStore.createList(channelId.value, selectedItem.value!.name);
  }
  // Add link to list
  await listStore.addLinkToList(selectedList!.id, linkId.value);
  isLoading.value = false;
  show.value = false;
}
</script>

<template>
<div class="pa-4 text-center">
    <v-dialog
      v-model="show"
      max-width="600"
    >
      <v-card
        :prepend-icon="mdiFormatListBulleted"
        title="Add to List"
      >
        <v-card-text>
          <v-row dense>
            <v-col
              cols="12"
              sm="12"
            >
              <v-combobox
                v-model="selectedItem"
                :items="lists"
                :loading="isLoading"
                :hide-no-data="false"
                hint="New list will be created after clicking the Add button."
                persistent-hint
                item-title="name"
                item-value="id"
                label="Selected list"
                @update:search="onSearchUpdate"
              >
                <template v-slot:no-data>
                  <v-list-item
                    v-if="newListName !== ''"
                    v-bind="props"
                    :prepend-icon="mdiPlus"
                    :title="`Click to create list: ${newListName}`"
                    @click="onCreateListClick"
                  ></v-list-item>
                </template>
              </v-combobox>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text="Close"
            variant="plain"
            @click="show = false"
          ></v-btn>

          <v-btn
            color="primary"
            text="Add"
            variant="tonal"
            @click="onAddBtnClick"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
</template>

<style scoped>
.link-list {
    list-style: none;
    padding: 0;
}
</style>