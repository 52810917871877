<script setup lang="ts">
import { useRoute } from 'vue-router';
import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useChannelStore } from '../store/channels';
import { useUserStore } from '../store/user';
import { hasChannelPermissions } from '../store/utils';
import { mdiChevronLeft } from '@mdi/js'

const route = useRoute();
const channelStore = useChannelStore();
const userStore = useUserStore();

let channelId = route.params.channelId as string;

watch(userStore.user, (newVal, _) => {getData(newVal)}, {immediate: true});

const { channel } = storeToRefs(channelStore);

const hasEditPermissions = ref(false);

function getData(newUser:any) {
    if ( !newUser.id ) return
    hasEditPermissions.value = hasChannelPermissions(userStore.user, channelId)
    channelStore.fetchChannel(channelId);
}
</script>

<template>
<router-link :to="`/channels/${channelId}`" :replace="true" class="back-link">
    <v-icon :icon="mdiChevronLeft" size="large" color="blue-grey-darken-2"></v-icon>
    back
</router-link>

<div class="channel-brand">
    <img class="channel-avatar" :src="channel.imageUrl" :alt="channel.name">
    <div class="channel-name">
        <h2>{{channel.name}}</h2>
        <p>{{channel.youtubeId}}</p>
    </div>
</div>

<h2 class="text-h2">Channel Settings</h2>
<!--
    Title: Account Settings
    Account level: 
    Refresh schedule: 
    Refresh now: button with counter (5 refreshes a month)
    Delete channel: button with confirmation
-->

</template>

<style scoped>
.channel-brand {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.channel-name {
    text-align: left;
}

.channel-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.back-link {
    text-align: left;
}
</style>