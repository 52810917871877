<script setup lang="ts">
import { useRoute } from 'vue-router';
import { ref, watch } from 'vue';
import { mdiChevronLeft } from '@mdi/js';
import LinkList from '../components/LinkList.vue';
import { useLinkStore } from '../store/links';
import { storeToRefs } from 'pinia';
import { useChannelStore } from '../store/channels';
import { useUserStore } from '../store/user';
import { hasChannelPermissions } from '../store/utils';
import { Link } from '../models/link';

const route = useRoute();
const linkStore = useLinkStore();
const channelStore = useChannelStore();
const userStore = useUserStore();

let channelId = route.params.channelId as string;
console.log(`Channel page for ${channelId}`);

const { channelLinks } = storeToRefs(linkStore);
const { channel } = storeToRefs(channelStore);
//const { user } = storeToRefs(userStore);

const hasEditPermissions = ref(false);
const results = ref(<Link[]>[]);

watch(userStore.user, (newVal, _) => {getData(newVal)}, {immediate: true});
watch(channelLinks, (newVal, _) => results.value = newVal);

function getData(newUser:any) {
    if ( !newUser.id ) return
    hasEditPermissions.value = hasChannelPermissions(userStore.user, channelId)
    linkStore.fetchChannelLinks(channelId);
    channelStore.fetchChannel(channelId);
}
</script>

<template>
<router-link :to="`/channels/${channelId}`" :replace="true" class="back-link">
    <div class="channel-brand">
        <v-icon :icon="mdiChevronLeft" size="large" color="blue-grey-darken-2"></v-icon>
        <img class="channel-avatar" :src="channel.imageUrl" :alt="channel.name">
        <div class="channel-name">
            <h2>{{channel.name}}</h2>
            <p>{{channel.youtubeId}}</p>
        </div>
    </div>
</router-link>

<LinkList :links="results" :is-channel-page="true" search-placeholder="Search links"/>

</template>

<style scoped>
.channel-brand {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.channel-name {
    text-align: left;
}

.channel-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.back-link {
    text-align: left;
}
</style>