/// <reference types="google.accounts, jsonwebtoken" />

<script setup lang="ts">
//import { JwtPayload } from 'jsonwebtoken';
import { ref, onMounted, provide, watch } from 'vue';
import { useUserStore } from '../store/user';
//import { User } from "../models/link";
import { mdiLink } from '@mdi/js';
import { AppConfig}  from '../config';

//let userPhoto = ref('');
//let userName = ref('');
const showYoutubeDialog = ref(false);

//var userTok: JwtPayload;

const userStore = useUserStore();

const isLoggedIn = ref(false);

watch(userStore.user, handleNewUser, {immediate: true});

function handleNewUser(newUser: any, _: any) {
    if (!newUser.id) {
        isLoggedIn.value = false;
    }
}

async function handleCredentialResponse(response: google.CredentialResponse) {
    //await getUserData(response.credential);
    await userStore.getGoogleUser(response.credential);
    userStore.login(response.credential).then(() => {
        provide('user', userStore.user);
    })
    .catch((error: Error) => {
        if (error.message == '401') {
            // show connect youtube message.
            showYoutubeDialog.value = true;
            return;
        }
    });
    isLoggedIn.value = true;
}

/*
async function getUserData(token: string) {
    const response = await fetch(`https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${token}`);
    const jsonData = await response.json();
    userTok = jsonData;
    userPhoto.value = jsonData.picture;
    userName.value = jsonData.name;
}
*/

/*
function signout() {
    google.accounts.id.revoke(userTok.sub!, done => {
        console.log(done.error);
    });
    google.accounts.id.disableAutoSelect();
    isLoggedIn.value = false;
    showDrawer.value = false;
    userStore.logout();
}

async function onUpdateChannels() {

}
*/

var windowObjectReference: any = null;
let previousURL: string;
const strWindowFeatures =
     'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';

/*
function onOpenConnectYoutubeDialog() {
    showYoutubeDialog.value = true;
}
*/
function onConnectYoutube() {
    showYoutubeDialog.value = false;
    function openRequestedSingleTab(url: string) {
        if (windowObjectReference === null || windowObjectReference.closed) {
            windowObjectReference = window.open(url, "ConnectYoutube", strWindowFeatures);
        } else if (previousURL !== url) {
            windowObjectReference = window.open(url, "ConnectYoutube", strWindowFeatures);
            windowObjectReference.focus();
        } else {
            windowObjectReference.focus();
        }
        windowObjectReference.addEventListener('message', (event:MessageEvent) => receiveMessage(event), false);
        windowObjectReference.addEventListener('beforeunload', (_:Event) => location.reload(), false);
        previousURL = url;
    }

    function receiveMessage(e: MessageEvent) {
        console.log(e);
        // get user data by updating the user.
        location.reload();
    }

    openRequestedSingleTab(AppConfig.DOMAIN + 'youtube-connect');
}

onMounted(() => {
    google.accounts.id.initialize({
        client_id: "15103251220-k3e1a9tm54ogrj5ou4n7d7640tbqagl6.apps.googleusercontent.com",
        auto_select: true,
        callback: handleCredentialResponse
    });
    google.accounts.id.renderButton(
        document.getElementById("googleLogin")!,
        { theme: "outline", size: "large", shape: "pill" }  // customization attributes
    );
    google.accounts.id.prompt(); // also display the One Tap dialog
});

</script>

<template>
<div id="googleLogin" v-show="!isLoggedIn"></div>

<!--
<v-btn
    v-if="isLoggedIn"
    variant="plain"
    :icon="mdiMenu"
    @click="showDrawer = !showDrawer"
    >
</v-btn>
-->

<v-dialog
    v-model="showYoutubeDialog"
    width="auto"
>
    <v-card
        max-width="400"
        title="Link to YouTube"
    >
        <!--
            clicjar logo, link icon, youtube logo
            Description of why.
            Will not be able to use clicjar with linking to youtube.
        -->
        <v-card-text>
            <div class="hero-illustration">
                <v-img src="/favicon.svg" height="50"></v-img>
                <v-icon :icon="mdiLink" class="mdi-rotate-45" size="x-large"></v-icon>
                <v-img src="https://developers.google.com/static/site-assets/logo-youtube.svg" height="50"></v-img>
            </div>
            <p class="description">
                Clicjar needs permission to link to your YouTube account to show you links from your subscriptions and know which channels you own. You will not be able to use Clicjar until you link your YouTube account.
            </p>
        </v-card-text>
        <template v-slot:actions>
            <v-btn
                class="ms-auto"
                text="Cancel"
                @click="showYoutubeDialog = false"
            ></v-btn>
            <v-btn
                class="ms-auto"
                color="primary"
                text="link to YouTube"
                @click="onConnectYoutube"
            ></v-btn>
        </template>
    </v-card>
</v-dialog>
</template>

<style scoped>
.user-avatar {
    max-width: 40px;
    max-height: 40px;
    border-radius: 100%;
}

.sign-out {
    cursor: pointer;
}

.hero-illustration {
    display: flex;
    margin: 50px 0;
    align-items: center;
    flex-direction: row;
}

.description {
    font-size: small;
}
</style>