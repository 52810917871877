<script setup lang="ts">
import { Link } from '../../models/link';
import { toRefs } from 'vue';
import { mdiChevronRight } from '@mdi/js';

    const props = defineProps<{link: Link}>();
    const { link } = toRefs(props);
</script>

<template>
<li>
    <router-link class="link link-affiliate" :to="`/channels/${link.channel.id}`">
        <div class="channel">
            <img class="channel-avatar" :src="link.channel.imageUrl" :alt="link.channel.name">
            <h3 class="channel-name">{{link.channel.name}}</h3>
        </div>
        <v-icon :icon="mdiChevronRight" size="large" color="blue-grey-darken-2"></v-icon>
    </router-link>
</li>
</template>

<style scoped>
    .link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border-radius: 5px;
    }
    .link:hover {
        background-color: #efefef;
        
    }
    .link-affiliate {
        display: flex
    }
    .channel {
        display: flex;
        align-items: center;
        
    }
    .channel-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
    .channel-name {
        font-size: 18px;
        line-height: 18px;
        margin-left: 10px;
        color: #333;
    }
</style>