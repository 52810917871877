import { defineStore } from "pinia";
import { ref } from "vue";
import { AppConfig } from "../config";
import { List } from "../models/link";
import { createLinkUrl } from "./utils";

export const useListsStore = defineStore('lists', () => {
    const lists = ref(<List[]>[]);
    const list = ref({} as List);

    async function fetchLists(channelId: string) {
      try {
        const response = await fetch(AppConfig.API_DOMAIN + `/channels/${channelId}/lists`, {
          credentials: 'include'
        });
        const res = await response.json();
        let _lists = res.map((el: any) => {
          return _convertResToList(el);
        });
        lists.value = _lists;
      }
      catch (error) {
          alert(error)
          console.log(error)
      }
    }

    async function createList(channelId: string, listName: string) {
      try {
        const reqData = JSON.stringify({name: listName, channelId: channelId});

        const response = await fetch(AppConfig.API_DOMAIN + `/channels/${channelId}/lists`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: reqData
        });

        const res = await response.json();
        lists.value.push({id: res.id, name: res.name} as List);

        return res;
      }
      catch (error) {
          alert(error)
          console.log(error)
      }
    }

    async function updateList(listId: string, listName: string) {
      try {
        const reqData = JSON.stringify({name: listName});

        const response = await fetch(AppConfig.API_DOMAIN + `/lists/${listId}`, {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: reqData
        });
        if (!response.ok) {
          throw new Error(`${response.status}: ${response.statusText}`);
        }

        let res = await response.json();
        res = _convertResToList(res);
        lists.value = lists.value.map((l: List) => l.id !== listId ? l : res);

        if (list.value.id === res.id) {
          list.value = res;
        }
      }
      catch (error) {
          alert(error)
          console.log(error)
      }
    }

    async function addLinkToList(listId: string, linkId: string) {
      try {
        const response = await fetch(AppConfig.API_DOMAIN + `/lists/${listId}/links/${linkId}`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        });
        if (!response.ok) {
          throw new Error(`${response.status}: ${response.statusText}`);
        }

        let res = await response.json();
        res = _convertResToList(res);
        lists.value = lists.value.map((l: List) => l.id !== listId ? l : res);

        if (list.value.id === res.id) {
          list.value = res;
        }
      }
      catch (error) {
          alert(error)
          console.log(error)
      }
    }
  
    async function fetchList(id: string) {
      try {
        const response = await fetch(AppConfig.API_DOMAIN + '/lists/' + id, {
          credentials: 'include'
        });
        if (!response.ok) {
          throw new Error(`${response.status}: ${response.statusText}`);
        }
        let res = await response.json();
        if (!!res) {
          list.value = _convertResToList(res);
          console.log(list.value);
        }
      }
      catch (error) {
        alert(error)
        console.log(error)
      }
    }

    function reset() {
      list.value = {} as List;
      lists.value = [];
    }

    function _convertResToList(res: any) {
      return {
        id: res.id,
        name: res.name,
        imageUrl: res.links && res.links.length > 0 ? res.links[0].imageUrl : '',
        links: _convertToLinks(res.links) //res.links.map(updateLinkUrl)
      } as List
    }

    function _convertToLinks(res: LinkRes[]) {
      return res.map((el: LinkRes) => ({
        id: el.id,
        url: createLinkUrl(AppConfig.DOMAIN, el.url, el.id),
        resolvedUrl: el.resolvedUrl,
        type: el.type,
        imageUrl: el.imageUrl || `https://www.google.com/s2/favicons?sz=64&domain=${el.domain}`,
        name: el.name || el.resolvedUrl || el.url,
        domain: el.domain,
        channel: {
            id: el.channelId,
            name: '',
            imageUrl: ''
        },
        discount: {
            amount: el.discount,
            code: el.code
        },
        isSponsored: el.isSponsored
      }));
    }

    return { lists, list,
      fetchList, fetchLists, createList, updateList, addLinkToList, reset }
  });

  export interface LinkRes {
    channelId: string,
    id: string
    url: string
    resolvedUrl?: string
    type: string
    name: string
    domain: string
    discount: string
    code: string
    imageUrl: string
    isSponsored: Boolean
  }
