<script setup lang="ts">
import { Link } from '../../models/link'
import { toRefs, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useLinkStore } from '../../store/links';
import { useChannelStore } from '../../store/channels';
import { useUserStore } from '../../store/user';
import { hasChannelPermissions } from '../../store/utils';
import { mdiDotsVertical, mdiPencil, mdiPlaylistPlus, mdiPlaylistStar, mdiDelete } from '@mdi/js';

    const props = defineProps<{link: Link, isChannelPage: boolean}>();
    const { link, isChannelPage } = toRefs(props);

    const emit = defineEmits<{
        (e: 'addToList', link: Link): void
        (e: 'linkDeleted', link: Link): void
    }>();

    const router = useRouter();

    const linkStore = useLinkStore();
    const channelStore = useChannelStore();
    const userStore = useUserStore();

    const showOptionsMenu = ref(hasChannelPermissions(userStore.user, link.value.channel.id) && isChannelPage);

    const optionItems = ref([
        {title: 'Edit', icon: mdiPencil, action: editLink, active: true},
        {title: 'Add to list', icon: mdiPlaylistPlus, action: addToList, active: true},
        {title: 'Add to quick links', icon: mdiPlaylistStar, action: makeQuickLink, active: !link.value.isQuickLink},
        {title: 'Delete', icon: mdiDelete, action: deleteLink, active: true},
    ]);

    function editLink() {
        // navigate user to link page to edit.
        router.push(`/channels/${link.value.channel.id}/links/${link.value.id}`);
    }

    function deleteLink() {
        // Confirm that they want to delete this link
        if (window.confirm("Are you sure you want to delete this link?")) {
            // make api call to delete the link
            linkStore.deleteLink(link.value);
        }
    }

    function addToList() {
        // show modal to select list to add to or create new list.
        emit("addToList", link.value);
    }

    function makeQuickLink() {
        // make api call to add to quicklinks.
        channelStore.addQuicklink(link.value.channel.id, link.value.id);
    }
</script>

<template>
    <v-list-item
        :title="link.name"
        :subtitle="link.domain"
        :href="link.url" target="_blank"
        :prepend-avatar="link.imageUrl"
        class="product-link"
    >
        <template v-slot:subtitle>
            <div class="subtitle">
                <div class="domain"> {{ link.domain }} </div>
                <div v-if="link.discount.amount.length || link.discount.code.length" class="discount">
                    Discount: {{ link.discount.amount }} Code: {{ link.discount.code }}
                </div>
            </div>
        </template>
        <template v-slot:append>
            <v-avatar
                v-if="!showOptionsMenu"
                :image="!isChannelPage ? link.channel.imageUrl: ''"
            ></v-avatar>
            <v-menu
                v-else
                @click.stop.prevent="">
                <template v-slot:activator="{ props }">
                    <v-btn
                        v-bind="props"
                        :icon="mdiDotsVertical"
                        elevation="0"
                        @click.stop.prevent=""
                    >
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item
                        v-for="(item, index) in optionItems"
                        :key="index"
                        :value="index"
                        @click="item.action"
                        :disabled="!item.active"
                    >
                        <template v-slot:prepend>
                            <v-icon :icon="item.icon"></v-icon>
                        </template>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
    </v-list-item>
</template>

<style scoped>
    .product-link {
        border-radius: 5px;
        text-align: left;
    }
    .product-link:hover {
        background-color: #efefef;
    }

    .subtitle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
</style>
