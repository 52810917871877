<script setup lang="ts">
import { ref, toRefs } from 'vue';
import { User } from '../models/link';

const props = defineProps<{user: User, showDrawer: boolean}>();
const { user, showDrawer } = toRefs(props);


const userMenuItems = ref([
    //{title: 'Update Channels', action: onUpdateChannels},
    //{title: 'Connect YouTube', action: onOpenConnectYoutubeDialog},
    {title: 'Sign out', action: signout},
]);


function signout() {
    return;
}
</script>

<template>

<v-navigation-drawer
    v-model="showDrawer"
    location="left"
    temporary
    >
    <v-list-item
        :prepend-avatar="user.imageUrl"
        :title="user.name"
    >
        <template v-slot:subtitle>
            <a class="sign-out" @click.stop.prevent="signout">Sign out</a>
        </template>
    </v-list-item>

    <v-divider></v-divider>

    <v-list density="compact" nav>
        <v-list-item
            v-for="(item, index) in userMenuItems"
            :key="index"
            :value="index"
            @click="item.action"
            >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
    </v-list>

    <v-list v-if="user.ownedChannels">
        <template v-for="(item, _) in user.ownedChannels">
            <v-list-subheader>{{item}}</v-list-subheader>

            <v-list-item
                v-for="(item, i) in user.ownedChannels"
                :key="i"
                :value="item"
                :title="item"
                color="primary"
                variant="plain"
                >
            </v-list-item>
        </template>
    </v-list>
</v-navigation-drawer>

</template>