<script setup lang="ts">
import { ref } from 'vue';

const links = ref([
    'Home',
    'Settings',
    'Privacy policy',
    'Terms of Service'
]);
</script>

<template>
<v-footer>
    <v-row justify="center" no-gutters>
      <v-btn
        v-for="link in links"
        :key="link"
        color="black"
        variant="text"
        class="mx-2"
        rounded="xl"
      >
        {{ link }}
      </v-btn>
      <v-col class="text-center mt-4" cols="12">
        {{ new Date().getFullYear() }} — <strong>Clicjar</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<style scoped>

</style>