<script setup lang="ts">
import { toRefs, ref, watch } from 'vue';
import AffiliateLink from './links/AffiliateLink.vue';
import { Link } from '../models/link';
import { mdiMagnify } from '@mdi/js';
import TestListItem from './links/TestListItem.vue';
import ChannelLink from './links/ChannelLink.vue';
import ListDialog from './dialogs/ListDialog.vue';

const searchText = ref('');

const props = defineProps({links: Array<Link>, isChannelPage: Boolean, searchPlaceholder: String});
const { links, isChannelPage, searchPlaceholder } = toRefs(props);
const results = ref(<Link[]>[]);

const showListDialog = ref(false);
const addToListLinkId = ref('');
const addToListChannelId = ref('');

watch(() => props.links!, (newVal: Link[], _) => {results.value = newVal}, {immediate: true});

function onAddToList(link: Link) {
    // show modal to select list to add to or create new list.
    addToListLinkId.value = link.id;
    addToListChannelId.value = link.channel.id;
    showListDialog.value = true;
}

function onLinkDeleted(link: Link) {
    if (!links?.value) return;

    for (var i = links?.value.length - 1; i >= 0; i--) {
        if (links?.value[i].id === link.id) {
            links?.value.splice(i, 1);
            return;
        }
    }
}

function onSearchUpdate() {
    console.log("search");
    if (!searchText.value || searchText.value == '') {
        onClear();
        return;
    }

    results.value = links!.value!.filter((l: Link) => {
        let name = l.name || l.channel.name;
        let url = l.url || '';
        return name.toLowerCase().includes(searchText.value.toLowerCase())
        || url.toLowerCase().includes(searchText.value.toLowerCase())
    });
}

function onClear() {
    results.value = links!.value || [];
}

</script>

<template>
<ListDialog :show="showListDialog" :channel-id="addToListChannelId" :link-id="addToListLinkId"/>

<div v-if="!links || !links?.length">
    <v-skeleton-loader
        v-for="index in 10" :key="index"
        type="list-item-avatar"></v-skeleton-loader>
</div>

<div v-if="links?.length">
    <v-container fluid>
        <v-row align="center">
            <v-col>
                <v-text-field
                    v-model="searchText"
                    hide-details="auto"
                    :label="searchPlaceholder || 'Search'"
                    variant="outlined"
                    density="compact"
                    :prepend-inner-icon="mdiMagnify"
                    clearable
                    rounded
                    @click:clear="onClear"
                    @update:modelValue="onSearchUpdate"
                ></v-text-field>
            </v-col>
        </v-row>
    </v-container>
    
    <v-list class="link-list" lines="two">
    <template v-for="link in results">
        <AffiliateLink
            v-if="link.type === 'affiliate' || link.type === 'support_platform'"
            :key="link.id"
            :link="link"></AffiliateLink>
        <TestListItem
            v-if="link.type === 'product'"
            :is-channel-page="isChannelPage"
            :key="link.id"
            :link="link"
            @add-to-list="onAddToList"
            @link-deleted="onLinkDeleted"></TestListItem>
        <ChannelLink
            v-if="link.type === 'channel'"
            :key="link.channel.id"
            :link="link"></ChannelLink>
        <TestListItem
            v-if="link.type === 'other' || link.type === 'social' || link.type === ''"
            :is-channel-page="isChannelPage"
            :key="link.id"
            :link="link"
            @add-to-list="onAddToList"
            @link-deleted="onLinkDeleted"></TestListItem>
    </template>
    </v-list>
</div>
</template>

<style scoped>
.link-list {
    list-style: none;
    padding: 0;
}
</style>